<template>
  <div class="carousel carousel--social-media">
    <transition-group class="columns is-mobile" tag="div">
      <div v-for="card in cards" :key="card.id" class="column is-3-desktop is-12-mobile slider" @mouseover="pause" @mouseleave="resume">
        <div class="overlay">
          <a :href="[card.url ? card.url : '#']">
            <div class="carousel__image" :style="{ 'background-image': 'url(' + card.image_url + ')' }">
              <div class="carousel__image--caption">
                {{ card.description }}
              </div>
              <span :class="[{ 'icon': true, 'icon--social-media': true, 'is-large': true }, card.icon ? card.icon : '']">
                <span class="fa-stack">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i :class="[{ 'fa': true, 'fa-stack-1x': true }, card.icon ? 'fa-' + card.icon : '']"></i>
                </span>
              </span>
            </div>
          </a>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      autoscroll: true,
      cards: [],
    };
  },
  mounted() {
    if (document.getElementById('carousels') !== null) {
      const dataElement = document.getElementById('carousels').dataset.socialMedia;
      const cards = JSON.parse(dataElement);
      if (cards.length > 0) {
        this.cards = cards;
        this.scroll();
      }
    }
  },
  methods: {
    next() {
      const first = this.cards.shift();
      this.cards = this.cards.concat(first);
    },
    scroll() {
      setInterval(() => {
        if (this.autoscroll) {
          this.next();
        }
      }, 2500);
    },
    pause() {
      this.autoscroll = false;
    },
    resume() {
      this.autoscroll = true;
    },
  },
};
</script>
