<template>
  <div class="columns">
    <div class="column is-11-desktop scroller scroller-recent-jobs">
      <div class="scroller__inner">
        <div v-for="slide in slides" :key="slide.id" class="scroller__item">
          <div class="recent__jobs__card">
            <div class="level level-jobs">
              <span class="media-card__flag is-pulled-right">{{ new_word }}</span>
            </div>
            <h1 class="media-card__title">{{ slide.title }}</h1>
            <h2 class="media-card__subtitle">{{ slide.subtitle }}</h2>
            <div class="media-card__content">
              <div class="level level-jobs">
                <div class="media-card__location">
                  <!-- <img src='~/images/icon-pin-location.svg' /> -->
                  {{ slide.event_location }}
                </div>
                <div class="media-card__ftpt">
                  {{ slide.icon }}
                </div>
              </div>
              <p class="recent__jobs__description">
                {{ slide.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'images/icon-pin-location.svg'
// import 'images/icon-arrow.svg'

export default {
  props: ['new_word'],
  data() {
    return {
      slides: [],
    };
  },
  mounted() {
    this.slides = JSON.parse(document.getElementById('carousels').dataset.jobs);
  },
};
</script>
